import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shouldLoad } from 'universal/http-client';
import {
  getABTestAssignments,
  CP_PERSONALIZATION_MBOX_ID,
  NEW_MOBILE_NAV_MBOX_ID,
  AEM_ABTEST_USERGROUP_MBOX_ID,
  getOptABTestAssignments,
  BGFPS,
} from 'shared/actions/actions-page';
import HomeApp from './app-home';

export class DumbHomeAppWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { callAbTestService: true };
  }

  UNSAFE_componentWillMount() {
    const mBoxIds = [];
    this.props.cpPersonalizationToggle &&
      mBoxIds.push(CP_PERSONALIZATION_MBOX_ID);
    this.props.newMobileNavToggle && mBoxIds.push(NEW_MOBILE_NAV_MBOX_ID);
    this.props.abtestAemContentToggle &&
      mBoxIds.push(AEM_ABTEST_USERGROUP_MBOX_ID);
    if (mBoxIds.length > 0) {
      if (shouldLoad(this.props.abTestApi)) {
        this.props.getABTestAssignments(mBoxIds.join(','), true);
      }
    } else {
      this.setState({ callAbTestService: false });
    }

    const opttIds = [];
    this.props.bgfpsToggle && opttIds.push(BGFPS);
    if (opttIds.length > 0) {
      if (shouldLoad(this.props.abTestOptApi)) {
        this.props.getOptABTestAssignments(opttIds.join(','));
      }
    }
  }

  render() {
    const shouldContinue =
      !this.state.callAbTestService ||
      (this.state.callAbTestService &&
        (this.props.abTestApi.resolved || this.props.abTestApi.rejected));
    return (
      <React.Fragment>
        {shouldContinue ? <HomeApp {...this.props} /> : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  cpPersonalizationToggle: state.toggles.ABTEST_COHERENT_PATH_PERSONALIZATION,
  newMobileNavToggle: state.toggles.NEW_MOBILE_NAV,
  abtestAemContentToggle: state.toggles.ABTEST_AEM_CONTENT_TARGETING,
  abTestApi: state.api.abtest || {},
  abTestOptApi: state.api.abtestopt || {},
  bgfpsToggle: state.toggles.BGFPS,
});

export default connect(
  mapStateToProps,
  { getABTestAssignments, getOptABTestAssignments },
)(DumbHomeAppWrapper);
